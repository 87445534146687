<template>
  <div v-if="count == 0" style="height: 60px">
    <span style="text-align: center; display: block; margin-top: 20px"
      >No Data</span
    >
  </div>
</template>

<script>
export default {
  props: {
    count: 0,
  },
};
</script>
